import { CERTIFICATE_TYPE, CERTIFICATE_WHEN } from "./constant"

export const calculateCertificatePrice = ({ certificateBrand, certificateType, certificateDomains, certificatePriceConfig }) => {
    const certificateWhen = CERTIFICATE_WHEN.APPLY
    const certificatePrice = certificatePriceConfig.filter(item => item.type === certificateType && item.brand === certificateBrand && item.when === certificateWhen)[0]

    const { packagePrice, unitSingleDomainPrice, unitWildcardDomainPrice, minDomainsNo } = certificatePrice
    if (certificateType === CERTIFICATE_TYPE.SINGLE) {
        return unitSingleDomainPrice
    }

    if (certificateType === CERTIFICATE_TYPE.WILDCARD) {
        return unitWildcardDomainPrice
    }

    // 如果有打包价格，则直接使用打包价格
    if (typeof packagePrice === 'number') {
        return packagePrice
    }

    const wildcardDomainsNo = certificateDomains.filter(item => item.startsWith('*.')).length
    // 如果少于最小证书数量，则需要按照最小证书数量进行扣费
    const singleDomainsNo = certificateDomains.length < minDomainsNo ? minDomainsNo - wildcardDomainsNo : certificateDomains.length - wildcardDomainsNo
    return unitSingleDomainPrice * singleDomainsNo + unitWildcardDomainPrice * wildcardDomainsNo
}
