import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Clipboard from 'react-clipboard.js'
import { Icon, Input, Button, Collapse, Alert, message } from 'antd'

class CertificateVersionDetail extends React.Component {
  static props = {
    CertificateVersionDetail: PropTypes.object
  }

  handleCopySuccess = (file) => {
    message.success(`文件 ${file} 内容复制成功`)
  }

  render() {
    const { certificateVersionDetail } = this.props

    return (
      <>
        <Collapse
          bordered={false}
        >
          <Collapse.Panel
            header={<span>文件(<strong>PEM格式</strong>)：&nbsp;<strong>cert.key</strong>&nbsp;<span style={{ position: 'relative' }}>- 私钥文件<p style={{ position: 'absolute', top: '-6px', right: '-30px', transform: 'scale(0.8)', background: '#f50', color: 'white', height: '19px', lineHeight: '16px', padding: '2px 3px' }}>推荐</p></span></span>}
            key="1"
            extra={
              <div onClick={(e) => e.stopPropagation()}>
                <Clipboard
                  className='ant-btn'
                  data-clipboard-text={certificateVersionDetail.detail.certKey}
                  onSuccess={this.handleCopySuccess.bind(this, 'cert.key')}
                  style={{ marginTop: '-5px' }}
                >
                  <Icon type='copy' />&nbsp;点击复制
                </Clipboard>
                <Button
                  style={{ marginTop: '-5px', marginLeft: '5px' }}
                  onClick={() => this.certKeyFileDownload.click()}>
                  <Icon type='download' />
                  <a
                    style={{ display: 'none' }}
                    download='cert.key'
                    href={URL.createObjectURL(new Blob([certificateVersionDetail.detail.certKey]))}
                    ref={(ref) => this.certKeyFileDownload = ref}>
                  </a>
                  &nbsp;点击下载.key后缀私钥文件
                </Button>
                <Button
                  style={{ marginTop: '-5px', marginLeft: '5px' }}
                  onClick={() => this.certKeyPEMFileDownload.click()}>
                  <Icon type='download' />
                  <a
                    style={{ display: 'none' }}
                    download='certkey.pem'
                    href={URL.createObjectURL(new Blob([certificateVersionDetail.detail.certKey]))}
                    ref={(ref) => this.certKeyPEMFileDownload = ref}>
                  </a>
                  &nbsp;点击下载.pem后缀私钥文件
                </Button>
              </div>
            }
          >
            <Input.TextArea value={certificateVersionDetail.detail.certKey} autoSize className='cert-file' />
          </Collapse.Panel>
          <Collapse.Panel
            header={<span>文件(<strong>PEM格式</strong>)：&nbsp;<strong>fullchain.cer</strong>&nbsp;<span style={{ position: 'relative' }}>- 证书文件(包含证书和中间证书)<p style={{ position: 'absolute', top: '-6px', right: '-30px', transform: 'scale(0.8)', background: '#f50', color: 'white', height: '19px', lineHeight: '16px', padding: '2px 3px' }}>推荐</p></span></span>}
            key="2"
            extra={
              <div onClick={(e) => e.stopPropagation()}>
                <Clipboard
                  className='ant-btn'
                  data-clipboard-text={certificateVersionDetail.detail.fullChainCerts}
                  onSuccess={this.handleCopySuccess.bind(this, 'fullchain.cer')}
                  style={{ marginTop: '-5px' }}
                >
                  <Icon type='copy' />&nbsp;点击复制
                </Clipboard>
                <Button
                  style={{ marginTop: '-5px', marginLeft: '5px' }}
                  onClick={() => this.fullChainCertsFileDownload.click()}>
                  <Icon type='download' />
                  <a
                    style={{ display: 'none' }}
                    download='fullchain.cer'
                    href={URL.createObjectURL(new Blob([certificateVersionDetail.detail.fullChainCerts]))}
                    ref={(ref) => this.fullChainCertsFileDownload = ref}>
                  </a>
                  &nbsp;点击下载.cer后缀证书文件
                </Button>
                <Button
                  style={{ marginTop: '-5px', marginLeft: '5px' }}
                  onClick={() => this.fullChainCertsPEMFileDownload.click()}>
                  <Icon type='download' />
                  <a
                    style={{ display: 'none' }}
                    download='fullchain.pem'
                    href={URL.createObjectURL(new Blob([certificateVersionDetail.detail.fullChainCerts]))}
                    ref={(ref) => this.fullChainCertsPEMFileDownload = ref}>
                  </a>
                  &nbsp;点击下载.pem后缀证书文件
                </Button>
              </div>
            }
          >
            <Input.TextArea value={certificateVersionDetail.detail.fullChainCerts} autoSize className='cert-file' />
          </Collapse.Panel>
          <Collapse.Panel
            header={<span>文件(<strong>PEM格式</strong>)：&nbsp;<strong>cert.cer</strong>&nbsp;<span style={{ position: 'relative' }}>- 证书文件(仅包含证书，需要与中间证书文件配合使用)</span></span>}
            key="3"
            extra={
              <div onClick={(e) => e.stopPropagation()}>
                <Clipboard
                  className='ant-btn'
                  data-clipboard-text={certificateVersionDetail.detail.cert}
                  onSuccess={this.handleCopySuccess.bind(this, 'cert.cer')}
                  style={{ marginTop: '-5px' }}
                >
                  <Icon type='copy' />&nbsp;点击复制
                </Clipboard>
                <Button
                  style={{ marginTop: '-5px', marginLeft: '5px' }}
                  onClick={() => this.certFileDownload.click()}>
                  <Icon type='download' />
                  <a
                    style={{ display: 'none' }}
                    download='cert.cer'
                    href={URL.createObjectURL(new Blob([certificateVersionDetail.detail.cert]))}
                    ref={(ref) => this.certFileDownload = ref}>
                  </a>
                  &nbsp;点击下载.cer后缀证书文件
                </Button>
                <Button
                  style={{ marginTop: '-5px', marginLeft: '5px' }}
                  onClick={() => this.certPEMFileDownload.click()}>
                  <Icon type='download' />
                  <a
                    style={{ display: 'none' }}
                    download='cert.pem'
                    href={URL.createObjectURL(new Blob([certificateVersionDetail.detail.cert]))}
                    ref={(ref) => this.certPEMFileDownload = ref}>
                  </a>
                  &nbsp;点击下载.pem后缀证书文件
                </Button>
              </div>
            }
          >
            <Input.TextArea value={certificateVersionDetail.detail.cert} autoSize className='cert-file' />
          </Collapse.Panel>
          <Collapse.Panel
            header={<span>文件(<strong>PEM格式</strong>)：&nbsp;<strong>chain.cer</strong>&nbsp;<span style={{ position: 'relative' }}>- 中间证书文件(需要与证书文件配合使用)</span></span>}
            key="4"
            extra={
              <div onClick={(e) => e.stopPropagation()}>
                <Clipboard
                  className='ant-btn'
                  data-clipboard-text={certificateVersionDetail.detail.intermediateCACert}
                  onSuccess={this.handleCopySuccess.bind(this, 'chain.cer')}
                  style={{ marginTop: '-5px' }}
                >
                  <Icon type='copy' />&nbsp;点击复制
                </Clipboard>
                <Button
                  style={{ marginTop: '-5px', marginLeft: '5px' }}
                  onClick={() => this.chainFileDownload.click()}>
                  <Icon type='download' />
                  <a
                    style={{ display: 'none' }}
                    download='chain.cer'
                    href={URL.createObjectURL(new Blob([certificateVersionDetail.detail.intermediateCACert]))}
                    ref={(ref) => this.chainFileDownload = ref}>
                  </a>
                  &nbsp;点击下载.cer后缀证书文件
                </Button>
                <Button
                  style={{ marginTop: '-5px', marginLeft: '5px' }}
                  onClick={() => this.chainPEMFileDownload.click()}>
                  <Icon type='download' />
                  <a
                    style={{ display: 'none' }}
                    download='chain.pem'
                    href={URL.createObjectURL(new Blob([certificateVersionDetail.detail.intermediateCACert]))}
                    ref={(ref) => this.chainPEMFileDownload = ref}>
                  </a>
                  &nbsp;点击下载.pem后缀证书文件
                </Button>
              </div>
            }
          >
            <Input.TextArea value={certificateVersionDetail.detail.intermediateCACert} autoSize className='cert-file' />
          </Collapse.Panel>
        </Collapse>
        <Alert message={<>如果需要两个证书文件，那么请使用：<span style={{ textDecoration: 'underline' }}>私钥文件cert.key</span> + <span style={{ textDecoration: 'underline' }}>证书文件(包含证书和中间证书)fullchain.cer</span></>} type="info" showIcon style={{ marginTop: '10px' }} />
        <Alert message={<>如果需要三个证书文件，那么请使用：<span style={{ textDecoration: 'underline' }}>私钥文件cert.key</span> + <span style={{ textDecoration: 'underline' }}>证书文件(仅包含证书)cert.cer</span> + <span style={{ textDecoration: 'underline' }}>中间证书文件chain.cer</span></>} type="info" showIcon style={{ marginTop: '10px' }} />

      </>
    )
  }
}

CertificateVersionDetail = withRouter(CertificateVersionDetail)

export default CertificateVersionDetail
