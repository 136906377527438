import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Icon, Button, message } from 'antd'
import moment from 'moment'
import { getCertificateVersionDetail } from '../../redux/certificate/version'
import { CERTIFICATE_VERSION_STATUS } from '../../utils/constant'
import CertificateVersionDetail from '../../components/CertificateVersionDetail'

class CertificateVersion extends React.Component {
  constructor(props) {
    super(props)

    this.searchCertificateVersionDetail()
  }

  searchCertificateVersionDetail = () => {
    this.props.dispatch(getCertificateVersionDetail({ certificateId: this.certificateId, certificateVersionId: this.certificateVersionId }))
  }

  get certificateId() {
    return this.props.location.pathname.split('/').slice(-3, -2)[0]
  }

  get certificateVersionId() {
    return this.props.location.pathname.split('/').slice(-1)[0]
  }

  handleCopySuccess = (file) => {
    message.success(`文件 ${file} 内容复制成功`)
  }

  render() {
    const { certificateVersionDetail } = this.props
    if (!certificateVersionDetail) {
      return null
    }
    // 由于创建证书页面也会调用获取证书详情接口，所以certificateVersionDetail在访问该页面前可能已存在
    // 而导致取到的certificateVersionDetail不是最新的，状态是进行中，所以这里要避免这种情况，防止下方取值异常。
    if (certificateVersionDetail.status !== CERTIFICATE_VERSION_STATUS.GENERATED
      && certificateVersionDetail.status !== CERTIFICATE_VERSION_STATUS.ERROR
      && certificateVersionDetail.status !== CERTIFICATE_VERSION_STATUS.REVOKED) {
      return null
    }

    return (
      <div className='detail'>
        <div>
          <p className='header'><Icon type="file-sync" /><Button type='link' onClick={() => { this.props.history.push(`/monitor/certificates/${certificateVersionDetail.certificateId}`) }}>{certificateVersionDetail.certificate.name}</Button>@{moment(certificateVersionDetail.createTime).format('YYYY-MM-DD HH:mm')}</p>
          {certificateVersionDetail.error ?
            <>
              <Row type='flex' align='middle' style={{ marginTop: '10px' }}>
                <Col span={2}>失败信息：</Col>
                <Col span={22}>{certificateVersionDetail.error}</Col>
              </Row>
            </> :
            <>
              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <CertificateVersionDetail certificateVersionDetail={certificateVersionDetail} />
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col span={24} style={{ textAlign: 'right' }}>
                  <Button type='link' href='/docs/certformat' target='_blank' rel='noopener noreferrer'>需要其他格式的证书文件？点击此出了解如何转换证书格式</Button>
                </Col>
              </Row>
            </>
          }
        </div>
      </div>
    )
  }
}

CertificateVersion = connect((state) => {
  return {
    certificateVersionDetail: state.certificate.version.certificateVersionDetail
  }
})(CertificateVersion)

export default CertificateVersion
