import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { CERTIFICATE_TYPE, CERTIFICATE_WHEN } from '../utils/constant'

class CertificatePrice extends React.Component {
  static props = {
    certificateBrand: PropTypes.string,
    certificateType: PropTypes.string,
    certificateDomains: PropTypes.string,
    isAutoUpdate: PropTypes.string,
    isFirstVersion: PropTypes.string,
  }

  render() {
    let { certificateBrand, certificateType, certificateDomains, isFirstVersion, isAutoUpdate, configs: { certificatePrice: certificatePriceConfig } } = this.props
    certificateDomains = certificateDomains || []
    
    if (!certificatePriceConfig) {
      return <></>
    }

    const freePrice = (
      <>
        费用(1RMB = 100 OHTTPS余额)：<span className='font14' style={{ fontWeight: '600', marginLeft: '3px' }}>免费</span>
      </>
    )

    let certificateWhen
    if (isFirstVersion) {
      certificateWhen = CERTIFICATE_WHEN.APPLY
    } else if (isAutoUpdate) {
      certificateWhen = CERTIFICATE_WHEN.AUTO_UPDATE
    } else {
      certificateWhen = CERTIFICATE_WHEN.MAN_UPDATE
    }

    const certificatePrice = certificatePriceConfig.filter(item => item.type === certificateType && item.brand === certificateBrand && item.when === certificateWhen)[0]
    const { packagePrice, unitSingleDomainPrice, unitWildcardDomainPrice, minDomainsNo } = certificatePrice

    if (certificateType === CERTIFICATE_TYPE.SINGLE) {
      if (!unitSingleDomainPrice) {
        return freePrice
      }
      return (
        <>
          费用(1RMB = 100 OHTTPS余额)：<span className='font14' style={{ fontWeight: '600', marginLeft: '3px', color: '#f50' }}>{unitSingleDomainPrice / 100}</span>&nbsp;RMB
        </>
      )
    }

    if (certificateType === CERTIFICATE_TYPE.WILDCARD) {
      if (!unitSingleDomainPrice) {
        return freePrice
      }
      return (
        <>
          费用(1RMB = 100 OHTTPS余额)：<span className='font14' style={{ fontWeight: '600', marginLeft: '3px', color: '#f50' }}>{unitWildcardDomainPrice / 100}</span>&nbsp;RMB
        </>
      )
    }

    // 如果有打包价格，则直接使用打包价格
    if (typeof packagePrice === 'number') {
      if (!packagePrice) {
        return freePrice
      }
      return (
        <>
          费用(1RMB = 100 OHTTPS余额)：<span className='font14' style={{ fontWeight: '600', marginLeft: '3px', color: '#f50' }}>{packagePrice / 100}</span>&nbsp;RMB
        </>
      )
    }

    if (certificateType === CERTIFICATE_TYPE.MULTI) {
      const wildcardDomainsNo = certificateDomains.filter(item => item.startsWith('*.')).length
      // 如果少于最小证书数量，则需要按照最小证书数量进行扣费
      const singleDomainsNo = certificateDomains.length > minDomainsNo ? certificateDomains.length - wildcardDomainsNo : minDomainsNo - wildcardDomainsNo
      const price = unitWildcardDomainPrice * wildcardDomainsNo + unitSingleDomainPrice * singleDomainsNo
      return (
        <>
          <p>
            费用(1RMB = 100 OHTTPS余额)：<span className='font14' style={{ fontWeight: '600', marginLeft: '3px', color: '#f50' }}>{unitWildcardDomainPrice / 100}(每泛域名) x {wildcardDomainsNo} + {unitSingleDomainPrice / 100}(每单域名) x {singleDomainsNo} = <span style={{fontWeight: 'normal'}}> </span>{price / 100}</span>&nbsp;RMB
          </p>
          <p>
            域名数量不能少于{minDomainsNo}个，如果不足{minDomainsNo}个，则按照{minDomainsNo}个进行扣费
          </p>
        </>
      )
    }

    if (certificateType === CERTIFICATE_TYPE.IP) {
      const price = unitSingleDomainPrice * certificateDomains.length
      return (
        <>
          <p>
            费用(1RMB = 100 OHTTPS余额)：<span className='font14' style={{ fontWeight: '600', marginLeft: '3px', color: '#f50' }}>{unitSingleDomainPrice / 100}(每IP) x {certificateDomains.length} = <span style={{fontWeight: 'normal'}}> </span>{price / 100}</span>&nbsp;RMB
          </p>
        </>
      )
    }
  }
}

CertificatePrice = connect((state) => {
  return {
    configs: state.common.configs
  }
})(CertificatePrice)

export default CertificatePrice
