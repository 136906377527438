import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import psl from 'psl'
import { Button, Row, Col, Icon, Alert, Table, Modal, message } from 'antd'
import Clipboard from 'react-clipboard.js'
import { createCertificateOrder } from '../redux/certificate'
import { checkDnsCname, checkHttpFile } from '../redux/common'
import { createCertificateVersion } from '../redux/certificate/version'
import { ACME_CHALLENGE_TYPE, CERTIFICATE_AUTH_MODE } from '../utils/constant'
import HttpManAuthModeServerFileSettings from './HttpManAuthModeServerFileSettings'

class CreateCertificateVersionModal extends React.Component {
    static props = {
        certificateDetail: PropTypes.any,
        onOk: PropTypes.func,
        onCancel: PropTypes.func,
    }

    constructor(props) {
        super(props)

        this.state = {
            serverVerified: false,
            serverVerifying: false,
        }
        const { certificateDetail } = props
        this.props.dispatch(createCertificateOrder({
            type: certificateDetail.type,
            brand: certificateDetail.brand,
            domains: certificateDetail.domains,
            authMode: certificateDetail.authMode,
        }))
    }

    handleCopyAliasDomainRecordSuccess = () => {
        message.success('解析记录已成功复制到剪贴板')
    }

    handleCopyAliasDomainRecordValueSuccess = () => {
        message.success('解析记录值已成功复制到剪贴板')
    }

    handleClose = () => {
        const { onClose } = this.props
        onClose()
    }

    handleCheckDnsCname = () => {
        const { type: certificateType, brand: certificateBrand, domains: certificateDomains } = this.props.certificateDetail
        const { certificateThirdOrder } = this.props.certificate

        this.setState({
            serverVerified: false,
            serverVerifying: true
        })

        const certificateThirdOrderId = certificateThirdOrder ? certificateThirdOrder.id : undefined
        this.props.dispatch(checkDnsCname({ certificateType, certificateBrand, domains: certificateDomains, certificateThirdOrderId })).then((response) => {
            if (response.errorMsg) {
                this.setState({
                    serverVerifying: false,
                    serverVerified: false
                })
                return message.error(response.errorMsg)
            }

            if (response.payload.checked) {
                this.setState({
                    serverVerifying: false,
                    serverVerified: true
                })
                return message.success('验证解析记录添加成功')
            } else {
                this.setState({
                    serverVerifying: false,
                    serverVerified: false
                })
                return message.error('验证解析记录未添加成功，如您已正确添加，请等待域名解析记录生效后重试')
            }
        })
    }

    handleCheckHttpFile = () => {
        const { certificateThirdOrder } = this.props.certificate

        this.setState({
            serverVerified: false,
            serverVerifying: true
        })

        const certificateThirdOrderId = certificateThirdOrder ? certificateThirdOrder.id : undefined
        this.props.dispatch(checkHttpFile({ certificateThirdOrderId })).then((response) => {
            if (response.errorMsg) {
                this.setState({
                    serverVerifying: false,
                    serverVerified: false
                })
                return message.error(response.errorMsg)
            }

            if (response.payload.checked) {
                this.setState({
                    serverVerifying: false,
                    serverVerified: true
                })
                return message.success('验证服务端文件成功')
            } else {
                this.setState({
                    serverVerifying: false,
                    serverVerified: false
                })
                return message.error('验证服务端文件失败，请确认服务端文件创建成功后重试')
            }
        })
    }

    handleModalOk = () => {
        const { serverVerified, serverVerifying } = this.state
        const { certificate: { certificateThirdOrder }, certificateDetail: { id: certificateId, authMode }, onOk, isForRetry } = this.props

        if (serverVerifying) {
            switch (authMode) {
                case CERTIFICATE_AUTH_MODE.DNS_ALIAS_AUTH_MODE:
                    return message.error('请等待解析记录验证完成')
                case CERTIFICATE_AUTH_MODE.HTTP_MAN_AUTH_MODE:
                    return message.error('请等待服务端文件验证完成')
                default:
                    return
            }
        }

        if (!serverVerified) {
            switch (authMode) {
                case CERTIFICATE_AUTH_MODE.DNS_ALIAS_AUTH_MODE:
                    return message.error('请验证解析记录是否添加成功并生效')
                case CERTIFICATE_AUTH_MODE.HTTP_MAN_AUTH_MODE:
                    return message.error('请验证服务端文件')
                default:
                    return
            }
        }

        this.props.dispatch(createCertificateVersion({ certificateId, certificateThirdOrderId: certificateThirdOrder.id, isForRetry: false })).then((response) => {
            if (response.errorMsg) {
                return message.error(response.errorMsg)
            }
            if (isForRetry) {
                message.success('开始重新申请证书')
            } else {
                message.success('证书更新任务创建成功')
            }
            const certificateVersion = response.payload
            onOk(certificateVersion)
        })
    }

    handleModalCancel = () => {
        const { onCancel } = this.props
        onCancel()
    }

    renderDnsAliasAuthMode = () => {
        const { serverVerifying } = this.state
        const { isForRetry, certificate: { certificateThirdOrder } } = this.props

        if (!certificateThirdOrder) {
            return <></>
        }

        const { orderDetail: { _challengesDetail } } = certificateThirdOrder
        const challengesDetail = _challengesDetail.filter(item => item.type === ACME_CHALLENGE_TYPE.DNS_01)

        const columnsHost = [
            {
                title: '主机记录',
                dataIndex: 'recordHost',
                ellipsis: true,
                render: text => {
                    const lodash = '_'
                    const placeholder = 'ohttps-lodash-placeholder'
                    const mainDomain = psl.get(text.replaceAll(lodash, placeholder)).replaceAll(placeholder, lodash)
                    if (!text) {
                        return <>&nbsp;</>
                    } else {
                        return (
                            <div className='record-value'>
                                {text}
                                <Clipboard className='ant-btn' style={{ width: 150 }} data-clipboard-text={text.replace(`.${mainDomain}`, '')} onSuccess={this.handleCopyAliasDomainRecordSuccess}>
                                    <Icon type='copy' />&nbsp;点击复制解析记录
                                </Clipboard>
                            </div>
                        )
                    }
                }
            }
        ]

        const columnsValue = [
            {
                title: '记录类型',
                dataIndex: 'recordType'
            },
            {
                title: '记录值',
                dataIndex: 'recordValue',
                render: text => {
                    return (
                        <div className='record-value'>
                            {text}
                            <Clipboard className='ant-btn' style={{ width: 150 }} data-clipboard-text={text} onSuccess={this.handleCopyAliasDomainRecordValueSuccess}>
                                <Icon type='copy' />&nbsp;点击复制解析记录值
                            </Clipboard>
                        </div>
                    )
                }
            }
        ]

        const rowsHost = challengesDetail.map(item => {
            return {
                recordHost: item.data.domain
            }
        })
        const rowsValue = [{
            recordType: challengesDetail[0].data.type,
            recordValue: challengesDetail[0].data.value
        }]

        return (
            <Modal
                title={isForRetry ? '重新申请证书' : '更新证书(更新证书将会重新进行扣费，证书新版本有效期为365天(自今日起)，请谨慎操作)'}
                visible={true}
                width={1000}
                okType='primary'
                okButtonProps={{ style: { marginRight: '5px' } }}
                onOk={this.handleModalOk}
                onCancel={this.handleModalCancel}
            >
                <div className='dns-records'>
                    <Row type='flex' justify='start' gutter={16} align='middle'>
                        <Col span={24}>
                            <div>
                                <Alert message={`请添加以下${rowsHost.filter(item => item.recordHost).length}条记录至您的DNS域名解析记录中`} type="info" showIcon />
                                <Table
                                    columns={columnsHost}
                                    dataSource={rowsHost}
                                    size='small'
                                    pagination={false}
                                    bordered
                                    style={{ marginTop: '10px' }}
                                    className='record-type-value'
                                />
                                <p style={{ marginTop: '10px' }}>以上域名解析记录的记录类型和记录值统一为：</p>
                                <Table
                                    columns={columnsValue}
                                    dataSource={rowsValue}
                                    size='small'
                                    pagination={false}
                                    bordered
                                    style={{ marginTop: '-2px' }}
                                    className='record-type-value'
                                />
                                <Alert message="请不要删除以上DNS域名解析记录，否则会导致相关证书生成或更新失败" type="info" showIcon style={{ marginTop: '10px' }} />
                                <Alert message={`对于Sectigo证书品牌，每次证书生成或更新任务所需添加的DNS域名解析记录不同`} type="info" showIcon style={{ marginTop: '10px' }} />
                                <Alert message="免DNS授权模式暂不支持Sectigo证书的自动更新，如需证书的自动更新功能请使用DNS授权验证模式" type="info" showIcon style={{ marginTop: '10px' }} />
                            </div>
                        </Col>
                    </Row>
                    <Row type='flex' justify='end' align='middle' style={{ marginTop: '10px' }}>
                        <Col span={5} offset={19}>
                            <Button block onClick={this.handleCheckDnsCname} loading={serverVerifying}>验证解析记录</Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        )
    }

    renderHttpManAuthMode = () => {
        const { serverVerifying } = this.state
        const { isForRetry, certificate: { certificateThirdOrder } } = this.props

        if (!certificateThirdOrder) {
            return <></>
        }

        const { orderDetail: { _challengesDetail } } = certificateThirdOrder

        return (
            <Modal
                title={isForRetry ? '重新申请证书' : '更新证书(更新证书将会重新进行扣费，证书新版本有效期为365天(自今日起)，请谨慎操作)'}
                visible={true}
                width={1000}
                okType='primary'
                okButtonProps={{ style: { marginRight: '5px' } }}
                onOk={this.handleModalOk}
                onCancel={this.handleModalCancel}
            >
                <div className='dns-records'>
                    <Row type='flex' justify='start' gutter={16} align='middle'>
                        <Col span={24}>
                            <HttpManAuthModeServerFileSettings challengesDetail={_challengesDetail} />
                        </Col>
                    </Row>
                    <Row type='flex' justify='end' align='middle' style={{ marginTop: '10px' }}>
                        <Col span={5} offset={19}>
                            <Button block onClick={this.handleCheckHttpFile} loading={serverVerifying}>验证服务端文件</Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        )
    }

    render = () => {
        const { certificateDetail: { authMode } } = this.props
        if (authMode === CERTIFICATE_AUTH_MODE.DNS_ALIAS_AUTH_MODE) {
            return this.renderDnsAliasAuthMode()
        }
        if (authMode === CERTIFICATE_AUTH_MODE.HTTP_MAN_AUTH_MODE) {
            return this.renderHttpManAuthMode()
        }
    }
}

CreateCertificateVersionModal = connect((state) => {
    return {
        me: state.auth.me,
        certificate: state.certificate.certificate
    }
})(CreateCertificateVersionModal)

export default CreateCertificateVersionModal
